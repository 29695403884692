import React, {useState} from 'react'
// import SelectorSize from './SelectorSize';

export default function Checkout({data}) {
  const [selectedSize, setSelectedSize] = useState("");
  const handleSizeChange = (e) => {
    setSelectedSize(e.target.value);
  };
  const isOutOfStock = data.inOrder === "Out of Stock";
  return (
    <>
      <h2 className="text-center my-4">Checkout — AWWAL</h2>
      <div className="container">
        <div className="card my-5" style={{ maxWidth: "540px" }}>
          <div className="row g-0">
            <div className="col-md-4">
              <img
                src={data.link}
                className="img-fluid rounded-start"
                alt="..."
              />
            </div>
            <div className="col-md-8">
              <div className="card-body">
                <h5 className="card-title">{data.name}</h5>
                <p className="card-text">
                  {data.description} <hr /> {data.features} <hr />
                </p>
                <p className="card-text">
                  <small className="text-body-secondary">
                    Rs - {data.price}
                  </small>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <form
        className="mx-5 px-5 row g-3 pb-5"
        action="https://formspree.io/f/mjvngaay"
        method="POST"
      >
        <div className="col-md-3">
          <label htmlFor="inputEmail4" className="form-label">
            Name
          </label>
          <input
            type="text"
            className="form-control"
            id="name"
            name="name"
            required
          />
        </div>
        <div className="col-md-4">
          <label htmlFor="inputPassword4" className="form-label">
            E-Mail
          </label>
          <input
            type="email"
            className="form-control"
            id="email"
            name="email"
          />
        </div>
        <div className="col-sm-7">
          <label htmlFor="inputAddress" className="form-label">
            Address
          </label>
          <input
            type="text"
            className="form-control"
            id="inputAddress"
            name="address"
            required
          />
        </div>
        <div className="col-sm-7">
          <label htmlFor="inputAddress2" className="form-label">
            Phone No#
          </label>
          <input
            type="text"
            className="form-control"
            id="inputAddress2"
            name="phoneNo"
            required
          />
        </div>
        <div className="col-md-7">
          <label htmlFor="inputCity" className="form-label">
            City
          </label>
          <input
            type="text"
            className="form-control"
            id="inputCity"
            name="city"
            required
          />
        </div>
        <div className="col-sm-7">
          <label htmlFor="inputCity" className="form-label">
            Size
          </label>
          <select
            class="form-select form-select-sm"
            aria-label="Small select example"
            value={selectedSize}
            onChange={handleSizeChange}
            required
          >
            <option value="" disabled>
              Select size
            </option>
            {data.size.map((size, index) => (
              <option value={size}>{size}</option>
            ))}
          </select>
        </div>
        <div className="col-md-7">
          <label htmlFor="inputCity" className="form-label">
            Product
          </label>
          <input
            type="text"
            className="form-control"
            id="inputCity"
            name="article"
            value={`${data.name}, ${data.inOrder}, Size: ${selectedSize}`}
            readOnly
          />
        </div>
        <div className="col-12">
          <button
            type="submit"
            className="btn btn-dark mb-5"
            disabled={isOutOfStock}
          >
            {isOutOfStock ? "Out of Stock" : "Place Order"}
          </button>
        </div>
      </form>
    </>
  );
}
