import React from 'react'
import Img from './awwalfooter.png';
export default function ErrorPage() {
  return (
    <div className="text-center mt-5 pt-5">
        <h1>404 Error: Page Not found</h1>
      <h3>Seems Like you have been lost</h3>
      <img
        src={Img}
        alt="Not Found"
        style = {{height : "20em"}}
      >
      </img>
    </div>
  )
}
