import React from 'react'

export default function Size( { size }) {
  return (
    <div>
      {size.map((size, index) => (
        <div
          class="btn-group px-2"
          role="toolbar"
          aria-label="Toolbar with button groups"
        >
          <div
            class="btn-group mr-2"
            role="group"
            aria-label="First group"
          >
            <button type="button" class="btn btn-outline-dark" readOnly>
              {size}
            </button>
          </div>
        </div>
      ))}
    </div>
  );
}
