import React from 'react'

export default function Store({data}) {
  return (
    <>
      <div className="container my-4 pb-5">
        <h1 className="text-center">STORE — AWWAL</h1>
        <div className="row">
          {data.articles.map((item, index) => (
            <div key={index} className="col-sm-4 my-4">
              <div className="card bg-lg" style={{ width: "18rem" }}>
                <img src={item.link} className="card-img-top" alt="" />
                <div className="card-body">
                  <h5 className="card-title text-dark">{item.name}</h5>
                  <p className="card-text text-dark">{item.description}</p>
                  <hr/>
                  <p className="card-text text-dark">Rs - {item.price}</p>
                  <a
                    href={`/articles/${item.name}`}
                    rel="noreferrer"
                    className="btn btn-md btn-dark"
                  >
                    Shop
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
