import React from 'react'
import Size from  './Size.js';
import Img from './awwalfooter.png'
export default function Articles({data}) {
  let available = data.inOrder === "In Stock";
  return (
    <div className="container mt-5 mb-5 pb-5">
      <div className="row">
        <div className="col-sm-6 d-flex align-items-right justify-content-center">
          <img
            src={data.link}
            alt={data.name}
            className="img-fluid rounded"
            style={{ width: "50%", height: "auto" }}
          />
        </div>
        <div className="col-sm-1">
          <img src={Img} alt="Awwal" style={{ height: "7em" }}></img>
        </div>
        <div className="col-sm-2">
          <h2>{data.name}</h2>
          <p>{data.description}</p>
          <hr />
          <h3>FEATURES</h3>
          <hr />
          <p>{data.features}</p>
          <hr />
          <Size size={data.size} />
          <p>{data.inOrder}</p>
          <p>Price: Rs - {data.price}</p>
          <hr />
          <button className="btn btn-dark my-4" disabled={!available}>
            <a
              href={`/checkout/${data.name}`}
              className="text-decoration-none text-light"
            >
              {available ? "Buy Now" : "Out of Stock"}
            </a>
          </button>
        </div>
      </div>
    </div>
  );
}
