import Img1 from './awwalcarouselimg1.jpg'; 
import imgcard1 from './awwalCard.jpg'; 
import Img2 from './awwalcimg.jpg'; 
// import imgcard1 from './awwalcardimg1.jpg';
import imgcard2 from './awwalcardimg2.JPG';
// import Img3 from './awwalcarouselimg3.jpg'; 

export default function Home(){
    return (
      <>
        <div>
          <div
            id="carouselExampleDark"
            className="carousel carousel-dark slide"
          >
            <div className="carousel-indicators">
              <button
                type="button"
                data-bs-target="#carouselExampleDark"
                data-bs-slide-to="0"
                className="active"
                aria-current="true"
                aria-label="Slide 1"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleDark"
                data-bs-slide-to="1"
                aria-label="Slide 2"
              ></button>
            </div>
            <div className="carousel-inner">
              <div className="carousel-item active" data-bs-interval="10000">
                <img src={Img1} className="d-block w-100" alt="..." />
                <div className="carousel-caption d-none d-md-block">
                  <h5>On The Way - FW24</h5>
                  <p>Live Now.</p>
                </div>
              </div>
              <div className="carousel-item" data-bs-interval="2000">
                <img src={Img2} className="d-block w-100" alt="..." />
                <div className="carousel-caption d-none d-md-block">
                  <h5>On The Way - FW24</h5>
                  <p>
                    Live Now.
                  </p>
                </div>
              </div>
            </div>
            <button
              className="carousel-control-prev"
              type="button"
              data-bs-target="#carouselExampleDark"
              data-bs-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#carouselExampleDark"
              data-bs-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        </div>
        <div className="container-sm text-center my-4 pb-5">
          <h3 className="font-weight-bold">Store</h3>
          <div className="row row-cols-1 row-cols-md-4 g-4 my-2 justify-content-center">
            <div className="col">
              <div className="card">
                <a
                  className="text-decoration-none text-dark"
                  href="/wintercollection"
                >
                  <img src={imgcard1} className="card-img-top" alt="..." />
                  <div className="card-body">
                    <h5 className="card-title">On The Way - FW24 by اول</h5>
                    {/* <p className="card-text">
                      This is a longer card with supporting text below as a
                      natural lead-in to additional content. This content is a
                      little bit longer.
                    </p> */}
                  </div>
                </a>
              </div>
            </div>
            <div className="col">
              <div className="card">
                <a
                  className="text-decoration-none text-dark"
                  href="/summercollection"
                >
                  <img src={imgcard2} className="card-img-top" alt="..." />
                  <div className="card-body">
                    <h5 className="card-title">Flaming gust - VOL IV byاول</h5>
                    {/* <p className="card-text">
                      This is a longer card with supporting text below as a
                      natural lead-in to additional content. This content is a
                      little bit longer.
                    </p> */}
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </>
    );
}