import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import Navbar from "./components/Navbar";
import Home from "./components/Home";
import Footer from "./components/Footer";
import Warticles from "./winterdata.json";
import Sarticles from "./summerdata.json";
import SummerCollection from "./components/SummerCollection";
import WinterCollection from "./components/WinterCollection";
import Store from "./components/Store";
import data from "./alldata.json";
import Articles from "./components/Articles";
import Checkout from "./components/Checkout";
import ErrorPage from "./components/ErrorPage";
// import About from "./components/About";
function App() {
  return (
    <>
      <BrowserRouter>
        <Navbar/>
        <Routes>
          <Route path="*" element={<ErrorPage/>}/>
          <Route path="/" element={<Home/>}/>
          <Route path="/store" element={<Store data={data}/>}/>
          <Route path="/wintercollection" element={<WinterCollection data={Warticles}/>}/>
          <Route path="/summercollection" element={<SummerCollection data={Sarticles}/>}/>
          {/* <Route path="/about" element={<About/>}/> */}
          {
            data.articles.map((item)=>(
            <Route path={`/articles/${item.name}`} element = {<Articles data = {item}/>}/>
            ))
          }
          {
            data.articles.map((item) =>(
            <Route path={`/checkout/${item.name}`} element = {<Checkout data = {item}/>}/>
            ))
          }
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);