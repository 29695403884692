import React from "react";
// import Articles from "./Articles";
export default function WinterCollection({ data }) {
  return (
    <div className="container mb-5 pb-5">
      <h2 className="text-center my-4">On The Way - FW24 by اول</h2>
      <div className="row no-gutters">
        {data.articles.map((item, index) => (
          <div key={index} className="col-sm-4 my-4">
            <div className="card bg-light" style={{ width: "18rem" }}>
              <img src={item.link} className="card-img-top" alt="" />
              <div className="card-body">
                <h5 className="card-title text-dark">{item.name}</h5>
                <p className="card-text text-dark">{item.description}</p>
                <hr />
                <p className="card-text text-dark">Rs - {item.price}</p>
                <a
                  href={`/articles/${item.name}`}
                  target=""
                  rel="noreferrer"
                  className="btn btn-md btn-dark"
                >
                  Shop
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
