import './instaIcon.css'
export default function Footer() {
  return (
    <>
      <footer
        className="bg-body-tertiary text-center text-lg-start"
        style={{ bottom: 0, width: "100%", position: "fixed" }}
      >
        <div
          className="text-center p-3"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}
        >
          © 2024 Copyright:
          <a className="text-body" href="/">
            {" "}
            Awwal-Original
          </a>
        </div>
      </footer>
    </>
  );
}
